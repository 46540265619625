.App-row {
  max-width: 1038px;
  margin: 0 auto;
}

.Logo {
  width: 200px;
}

.Main-haslo {
  line-height: 3.25rem;
}

.Responsive-menu {
  top: 100px;
  background: white;
  padding: 6% 40px 40px 20px;
}

.Carusel-arrow-right {
  position: absolute;
    width: 100px;
    top: 400px;
}

.Carusel-arrow-left {
  position: absolute;
  width: 100px;
  top: 400px;
}

.carousel-container {
  align-items: flex-start !important;
}

.Skontaktuj {
  -webkit-box-shadow: 4px 4px 9px -6px rgba(0, 0, 0, 1);
  -moz-box-shadow: 4px 4px 9px -6px rgba(0, 0, 0, 1);
  box-shadow: 4px 4
  px 9px -6px rgba(0, 0, 0, 1);
}

/* animation */

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust duration and easing as needed */
}

.fade-in.loaded {
  opacity: 1;
}

/* accordion */

.JS-acc-content {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.JS-acc-content.JS-acc-active {
  height: auto;
}

.JS-acc-expand.JS-acc-active {
  /* display: none; */
  visibility: hidden;
}